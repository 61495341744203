<template>
 <!-- v-if="speakAlarm" -->
<div v-if="speakAlarm" class="alarm-notify-container" @click="showAlarmWin">
  <Icon type="md-call" class="alarm-notify-icon" />
  <div class="alarm-showing"></div>
</div>
</template>
<script>
import {mapState, mapGetters} from 'vuex'
export default {
  name: 'AlarmNotify',
  components: {
  },
  data(){
    return {
    }
  },
  computed: {
    ...mapState('cmd', ['zhuhaiSanrun']),
    ...mapGetters('cmd', ['speakAlarm'])
  },
  watch: {
    // 'zhuhaiSanrun.alarmComing'(){
    //   console.log('zhu hai san run alarm coming', this.zhuhaiSanrun, this.speakAlarm)
    // },
    // speakAlarm(){
    //   console.log('zhu hai san run alarm status', this.speakAlarm)
    // }
  },
  mounted: function(){
  },
  methods: {
    showAlarmWin: function(){
      // this.showAlarmModal = true;
      this.$emit('shown');
    },
  },
}
</script>
<style scoped>
.alarm-notify-container{
  position: absolute;
  top: 105px;
  right: 20px;
  width: 50px;
  height: 50px;
  border: solid 1px rgba(5, 5, 5, 0.377);
  border-radius: 10px;
  cursor: pointer;
}
.alarm-showing{
  position: absolute;
  border-left: solid 5px red;
  border-bottom: solid 5px red;
  border-radius: 10px;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  animation: shake-flag .8s linear;
  animation-iteration-count: infinite;
}
.alarm-notify-icon{
  /* width: 100%;
  height: 100%; */
  font-size: 50px;
  color: red;
  animation: shake-icon .8s;
  animation-iteration-count: infinite;
}
@keyframes shake-icon {
  0% {opacity: 1;}
  100% {opacity: 0.3;}
}
@keyframes shake-flag {
  0% {width: 60px;height: 60px;opacity: 1;}
  /* 10% {width: 70px;height: 70px;opacity: .9;} */
  20% {width: 80px;height: 80px;opacity: .8;}
  /* 30% {width: 90px;height: 90px;opacity: .7;} */
  40% {width: 100px;height: 100px;opacity: .6;}
  /* 50% {width: 110px;height: 110px;opacity: .5;} */
  60% {width: 120px;height: 120px;opacity: .4;}
  /* 70% {width: 130px;height: 130px;opacity: .3;} */
  80% {width: 140px;height: 140px;opacity: .2;}
  /* 90% {width: 150px;height: 150px;opacity: .1;} */
  100% {width: 160px;height: 160px;opacity: 0;}
}
</style>